import {BaseController} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/controller-factory/BaseController';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {getStyleParamsWithDefaultsFunc} from '../getStyleParamsWithDefaultsFunc';
import {IGalleryStyleParams} from '../types/galleryTypes';
import {appClient, Scope} from '@wix/app-settings-client';
import {APP_SETTINGS_CDN} from '@wix/wixstores-client-core/dist/es/src/constants';
import {SliderGalleryStore} from './SliderGalleryStore';

export class SliderGalleryControllerFlowEditor extends BaseController {
  private readonly compId: string;
  private sliderGalleryStore: SliderGalleryStore;

  /* istanbul ignore next: slider gallery has no velo exports */
  public exports(): {} {
    return {};
  }

  public onStyleUpdate = (newStyleParams: IGalleryStyleParams) => {
    this.styleParams = newStyleParams;
    this.sliderGalleryStore.updateState(newStyleParams, {APP: {}, COMPONENT: {}});
  };

  //todo(flow-editor): probably not an empty array
  public getFreeTexts = (): string[] => {
    return [];
  };

  private styleParams: any;
  private readonly config: ControllerParams['controllerConfig']['config'];
  private readonly type: string;

  constructor(controllerParams: ControllerParams) {
    super(controllerParams);
    this.type = controllerParams.controllerConfig.type;
    this.styleParams = controllerParams.controllerConfig.config.style.styleParams;
    this.config = controllerParams.controllerConfig.config;
    this.compId = controllerParams.controllerConfig.compId;

    const isEditor = typeof window !== 'undefined' && window.Wix;

    /* istanbul ignore else: todo(flow-editor): test? */
    if (isEditor) {
      this.listenToAppSettingsUpdate();
    }
  }

  private listenToAppSettingsUpdate() {
    const appSettingsClient = appClient({scope: Scope.COMPONENT, cdnUrl: APP_SETTINGS_CDN});
    appSettingsClient.onChange((pb) => {
      this.sliderGalleryStore.updateState(this.styleParams, {APP: undefined, COMPONENT: {}, appSettings: pb});
    });
  }

  public readonly load = async () => {
    const styleParamsWithDefaults = getStyleParamsWithDefaults(this.styleParams, () =>
      getStyleParamsWithDefaultsFunc({style: {styleParams: this.styleParams}, dimensions: undefined})
    );

    this.sliderGalleryStore = new SliderGalleryStore(
      styleParamsWithDefaults,
      this.config,
      this.setProps.bind(this),
      this.siteStore,
      this.compId,
      this.flowAPI.reportError,
      this.type,
      true
    );
    return this.sliderGalleryStore.setInitialState().catch(this.flowAPI.reportError);
  };

  public readonly init = async () => {
    await this.load();
  };
}
